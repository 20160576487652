import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { Location } from '@reach/router';

import ImageFavicon from '../assets/images/favicon/favicon.png';

const SEO = ({ title, description, image, location, postSEO}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `
  );

  const metaTitle = title || site.siteMetadata.title;
  const metaDescription = description || site.siteMetadata.description;
  const { siteUrl } = site.siteMetadata;
  const metaUrl = location.href || siteUrl + location.pathname;
  const metaImage = image ? siteUrl + image : ImageFavicon;
  // const index = index ? 'index':'noindex';
  // const follow = follow ? 'follow':'nofollow';
  // console.log("Title: " + title + " Index: " + index + " Follow: " + follow);
  //

  let metaArr = [];
  if (metaImage) {
    metaArr = metaArr.concat([
      {
        property: `og:image`,
        content: metaImage.replace('https', 'http'),
      },
      {
        property: `og:image:secure_url`,
        content: metaImage,
      },
      {
        name: `twitter:image`,
        content: metaImage,
      },
    ]);
  }

  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: metaUrl,
      name: metaTitle,
      alternateName: 'Sprive',
    },
  ];

  const logoJSONLD = {
    '@type': 'ImageObject',
    url: siteUrl + ImageFavicon,
  };

  if (postSEO) {
    const authorJSONLD = {
      '@type': 'Person',
      name: postSEO.authorName,
      // email: config.userEmail,
      // address: config.userLocation
    };

    schemaOrgJSONLD.push(
      {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            item: {
              '@id': metaUrl,
              name: metaTitle,
              image: metaImage,
            },
          },
        ],
      },
      {
        '@context': 'http://schema.org',
        '@type': 'BlogPosting',
        url: metaUrl,
        name: metaTitle,
        alternateName: 'Sprive',
        headline: metaTitle,
        image: { '@type': 'ImageObject', url: metaImage },
        author: authorJSONLD,
        publisher: {
          '@type': 'Organization',
          name: 'Sprive',
          logo: logoJSONLD,
        },
        datePublished: postSEO.datePublished,
        description,
      }
    );
  }

  return (
    <Helmet
      htmlAttributes={{ lang: 'en', prefix: 'og: http://ogp.me/ns#' }}
      title={metaTitle}
      meta={[
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1.0, viewport-fit=cover"
        },
        {
          name: `title`,
          content: metaTitle,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: metaUrl,
        },
        {
          property: `og:site_name`,
          content: site.siteMetadata.title,
        },
        {
          property: `og:image:width`,
          content: '512',
        },
        {
          property: `og:image:height`,
          content: '512',
        },

        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:site`,
          content: site.siteMetadata.title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:url`,
          content: metaUrl,
        }
      ].concat(metaArr)}
    >
      {/* Schema.org tags */}
      <script type="text/javascript" src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>
    </Helmet>
  );
};

const WithLocationSEO = (props) => (
  <Location>{({ location }) => <SEO {...props} location={location} />}</Location>
);

export default WithLocationSEO;
